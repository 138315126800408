import { FormEvent, useEffect, useState } from "react";
import { Button } from "../../../components/Button";
import useAnalyticsEventTracker from "../../../tracker/EventTracker";
import { InputPassword } from "../../../components/InputPassword";
import { Input } from "../../../components/Input";
import { InputPhone } from "../../../components/InputPhone";
import { CompanyInfo, Cpv, UserInfo } from "AppTypes";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Spinner } from "../../../components/Spinner";
import { KvkInput } from "../../../components/kvkInput";

interface LandingProps {
    setPage: (page: string) => void;
    userInfo: UserInfo;
    updateUserInfo: (userInfo: UserInfo) => void;
    companyInfo: CompanyInfo;
    updateCompanyInfo: (companyInfo: CompanyInfo) => void;
}

export const Register = ({
    setPage,
    userInfo,
    updateUserInfo,
    companyInfo,
    updateCompanyInfo,
}: LandingProps) => {
    const [errorMessage, setErrorMessage] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false);

    useEffect(() => {
        EventTracker();
    }, []);

    function EventTracker() {
        useAnalyticsEventTracker(
            "Register user",
            "Bekijkt gebruiker aanmaken",
            "Register > Gegevens invullen"
        );
    }

    function EventTrackRegister(label: string) {
        useAnalyticsEventTracker(
            "Register user",
            label,
            "Register > Gegevens invullen"
        );
    }

    async function HandleRegister() {
        setLoading(true);
        const generatedID = companyInfo.chamberOfCommerce.concat(
            new Date().valueOf().toString()
        );
        var checkup = Validator();
        if (checkup === true) {
            await Auth.signUp({
                username: userInfo.email,
                password: userInfo.password,
                attributes: {
                    email: userInfo.email,
                },
            })
                .then(async ({ userSub }) => {
                    handleUserInfoStr(userSub, "id");
                    await CreateUser(userSub);
                    EventTrackRegister("Account aangemaakt");
                })
                .catch((error) => {
                    if (error.toString().includes("UsernameExistsException")) {
                        setErrorMessage([
                            `Er bestaat al een account met dit emailadres. Ga terug om in te loggen.`,
                        ]);
                    } else {
                        setErrorMessage([
                            `Er is wat misgegaan bij het aanmaken van je account. Probeer het later nog eens of neem contact op met ons: service@tendertracer.nl`,
                        ]);
                    }
                    console.log(error);
                    setLoading(false);
                    EventTrackRegister("Account aanmaken mislukt");
                });
        } else {
            setLoading(false);
            EventTrackRegister("Velden onjuist ingevoerd");
        }
    }

    async function CreateUser(userSub: string) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            id: userSub,
            testMode: false,
            userInfo: {
                firstname: userInfo.firstname,
                lastname: userInfo.lastname,
                email: userInfo.email,
                phone: `+${userInfo.phone}`,
            },
            companyInfo: {
                chamberOfCommerce: companyInfo.chamberOfCommerce,
                name: companyInfo.name,
                address: companyInfo.address,
                postalcode: companyInfo.postalcode,
                country: companyInfo.country,
                city: companyInfo.town,
            },
        });

        var requestOptions: any = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        await fetch(
            "https://zdmddmq6phupw66trd4s6tf7ke0apefb.lambda-url.eu-west-3.on.aws/",
            requestOptions
        )
            .then(async (response) => {
                await Auth.signIn(userInfo.email, userInfo.password);
                window.location.href = "/";
            })
            .catch((error) => {
                setErrorMessage([
                    `Er is wat misgegaan bij het aanmaken van je account. Probeer het later nog eens of neem contact op met ons: service@tendertracer.nl`,
                ]);
                EventTrackRegister("Account aanmaken mislukt");
                console.log(error);
                setLoading(false);
            });
    }

    function Validator() {
        console.log(userInfo);
        var errors = [];
        if (
            userInfo.firstname === "" ||
            userInfo.lastname === "" ||
            userInfo.email === "" ||
            userInfo.phone === "" ||
            companyInfo.name === "" ||
            companyInfo.address === "" ||
            companyInfo.postalcode === "" ||
            companyInfo.town === "" ||
            companyInfo.country === "" ||
            userInfo.password === "" ||
            userInfo.passwordValid === ""
        ) {
            errors.push("Vul alle velden in.");
        } else if (
            !userInfo.email.includes("@") &&
            !userInfo.email.includes(".")
        ) {
            errors.push("Vul een geldig emailadres in.");
        } else if (userInfo.phone.length !== 11) {
            errors.push("Vul een geldig telefoonnummer in.");
        } else if (userInfo.password !== userInfo.passwordValid) {
            errors.push("Uw wachtwoorden komen niet met elkaar overeen.");
        } else if (
            userInfo.password.length < 6 ||
            userInfo.password.length > 99
        ) {
            errors.push(
                "Uw wachtwoord moet bestaan uit minimaal 6 en maximaal 99 karakters."
            );
        } else if (!/[A-Z]/.test(userInfo.password)) {
            errors.push("Uw wachtwoord moet minimaal 1 hoofdletter bevatten.");
        } else if (!/[a-z]/.test(userInfo.password)) {
            errors.push(
                "Uw wachtwoord moet minimaal 1 kleine letter bevatten."
            );
        } else if (!/[0-9]/.test(userInfo.password)) {
            errors.push("Uw wachtwoord moet minimaal 1 cijfer bevatten.");
        } else if (!/[!@#$%^&*]/.test(userInfo.password)) {
            errors.push("Uw wachtwoord moet minimaal 1 symbool bevatten.");
        }

        if (errors.length > 0) {
            setErrorMessage(errors);
            setLoading(false);
            return false;
        } else {
            setErrorMessage([]);
            return true;
        }
    }

    const handleUserInfo = (
        event: FormEvent<HTMLInputElement>,
        key: keyof UserInfo
    ) => {
        const updatedUserInfo = { ...userInfo };
        updatedUserInfo[key] = event.currentTarget.value;
        updateUserInfo(updatedUserInfo);
    };

    const handleUserInfoStr = (event: string, key: keyof UserInfo) => {
        const updatedUserInfo = { ...userInfo };
        updatedUserInfo[key] = event;
        updateUserInfo(updatedUserInfo);
    };

    const handleCompanyInfoStr = (
        companyInfo: CompanyInfo,
        event: string,
        key: keyof CompanyInfo
    ): CompanyInfo => {
        const updatedCompanyInfo = { ...companyInfo };
        updatedCompanyInfo[key] = event;
        return updatedCompanyInfo;
    };

    const fetchDetailedKvk = async (chamberOfCommerce: number) => {
        var requestOptions: any = {
            method: "POST",
            redirect: "follow",
        };

        const response = await fetch(
            `https://hjniserla6nyigpuahcj7whvn40kabxh.lambda-url.eu-west-3.on.aws/?key=${chamberOfCommerce}`,
            requestOptions
        );
        var data = await response.json();

        const updateItem = (kvkData: any, chamberOfCommerce: number) => {
            let updatedCompanyInfo: CompanyInfo = { ...companyInfo };
            updatedCompanyInfo = handleCompanyInfoStr(
                updatedCompanyInfo,
                kvkData["naam"],
                "name"
            );
            updatedCompanyInfo = handleCompanyInfoStr(
                updatedCompanyInfo,
                kvkData._embedded.hoofdvestiging.adressen[0]["straatnaam"],
                "address"
            );
            updatedCompanyInfo = handleCompanyInfoStr(
                updatedCompanyInfo,
                kvkData._embedded.hoofdvestiging.adressen[0]["huisnummer"],
                "number"
            );
            updatedCompanyInfo = handleCompanyInfoStr(
                updatedCompanyInfo,
                kvkData._embedded.hoofdvestiging.adressen[0]["postcode"],
                "postalcode"
            );
            updatedCompanyInfo = handleCompanyInfoStr(
                updatedCompanyInfo,
                kvkData._embedded.hoofdvestiging.adressen[0]["plaats"],
                "town"
            );
            updatedCompanyInfo = handleCompanyInfoStr(
                updatedCompanyInfo,
                kvkData._embedded.hoofdvestiging.adressen[0]["land"] ===
                    "Nederland"
                    ? "NL"
                    : kvkData._embedded.hoofdvestiging.adressen[0]["land"],
                "country"
            );
            updatedCompanyInfo = handleCompanyInfoStr(
                updatedCompanyInfo,
                chamberOfCommerce.toString(),
                "chamberOfCommerce"
            );

            updateCompanyInfo(updatedCompanyInfo);
        };
        updateItem(data, chamberOfCommerce);
    };

    return (
        <div className="w-screen h-screen 2xl:h-full bg-[#284F82] bg-center bg-cover lg:bg-[url('/public/images/pexels-fauxels-3184405.webp')]">
            <div className="lg:w-3/5 h-full 2xl:rounded-lg relative flex flex-col items-center overflow-auto">
                <div className="h-40 w-full bg-light-blue 2xl:rounded-tl-lg">
                    <div className="flex w-full justify-between">
                        <div className="flex-col w-full">
                            <div className="flex">
                                <div className="w-full h-10 bg-[length:1100px_550px] bg-[url('/public/images/onboarding_bg.webp')]" />
                            </div>
                            <div className="pt-5 pl-6 pb-5">
                                <strong className="text-3xl text-white">
                                    Account aanmaken
                                </strong>
                                <p className="text-sm text-white font-bold mt-1">
                                    ✓ Volledig gratis account ✓ Geen
                                    betaalgegevens nodig ✓ Nergens aan vast
                                </p>
                            </div>
                        </div>
                        <div className="bg-white flex items-center justify-center">
                            <img
                                src="/images/TenderTracer_logo.png"
                                alt="Tendertracer Logo"
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full h-full bg-white lg:px-20 px-5 pt-10">
                    <div className="w-full flex flex-col justify-left items-left">
                        <p className="font-bold pb-2">Bedrijfsgegevens</p>
                        <KvkInput
                            onClick={(fetchedKvkData) =>
                                fetchDetailedKvk(fetchedKvkData.kvkNummer)
                            }
                        />
                    </div>
                    <div className="mt-10">
                        <div className="w-full flex pb-3 justify-left items-left">
                            <p className="font-bold">Persoonsgegevens</p>
                        </div>
                        <div className="grid grid-cols-2 gap-6">
                            <Input
                                label="Voornaam"
                                value={userInfo.firstname}
                                required={false}
                                placeholder="Vul hier je voornaam in"
                                onChange={(e: FormEvent<HTMLInputElement>) =>
                                    handleUserInfo(e, "firstname")
                                }
                            />
                            <Input
                                label="Achternaam"
                                value={userInfo.lastname}
                                required={false}
                                placeholder="Vul hier je achternaam in"
                                onChange={(e: FormEvent<HTMLInputElement>) =>
                                    handleUserInfo(e, "lastname")
                                }
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-6 pt-3">
                            <Input
                                label="Emailadres"
                                value={userInfo.email}
                                required={false}
                                type="email"
                                placeholder="Vul hier je e-mailadres in"
                                onChange={(e: FormEvent<HTMLInputElement>) =>
                                    handleUserInfo(e, "email")
                                }
                            />
                            <InputPhone
                                label="Telefoonnummer"
                                required={false}
                                value={userInfo.phone}
                                onChange={(e: string) =>
                                    handleUserInfoStr(e, "phone")
                                }
                            />
                        </div>
                    </div>

                    <div className="mt-5">
                        <div className="w-full flex pb-5 justify-left items-left">
                            <p className="font-bold">Accountsgegevens</p>
                        </div>
                        <InputPassword
                            label="Wachtwoord"
                            required={false}
                            value={userInfo.password}
                            placeholder="Vul hier je wachtwoord in"
                            onChange={(e: FormEvent<HTMLInputElement>) =>
                                handleUserInfo(e, "password")
                            }
                        />
                        <div className="pt-3">
                            <InputPassword
                                label="Nogmaals wachtwoord"
                                required={false}
                                value={userInfo.passwordValid}
                                placeholder="Vul hier nogmaals je wachtwoord in"
                                onChange={(e: FormEvent<HTMLInputElement>) =>
                                    handleUserInfo(e, "passwordValid")
                                }
                            />
                        </div>
                    </div>
                    <div className="text-center pt-5 pb-5">
                        <p style={{ color: "#ff4d72" }}>{errorMessage[0]}</p>
                    </div>
                    <div className="flex justify-between pb-10">
                        <Button
                            type="ghost"
                            onClick={() => (window.location.href = "/login")}
                        >
                            TERUG
                        </Button>
                        <Button
                            type={loading === false ? "pink" : "hidden"}
                            onClick={() =>
                                loading === false ? HandleRegister() : undefined
                            }
                        >
                            {loading === false ? (
                                "ACCOUNT AANMAKEN"
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <div style={{ paddingRight: "15%" }}>
                                        <p>Laden...</p>
                                    </div>
                                    <div>
                                        <Spinner />
                                    </div>
                                </div>
                            )}
                        </Button>
                    </div>
                    <p className="text-center text-xs italic pb-10">
                        Door op de knop "account aanmaken" te klikken, ga je
                        akkoord met onze{" "}
                        <a
                            className="text-primary-green hover:underline"
                            href="https://tendertracer.nl/wp-content/uploads/2023/06/r-B.V.-TenderTracer-Algemene-Leveringsvoorwaarden.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            algemene-{" "}
                        </a>
                        en{" "}
                        <a
                            className="text-primary-green hover:underline"
                            href="https://tendertracer.nl/privacy"
                            target="_blank"
                            rel="noreferrer"
                        >
                            privacyvoorwaarden
                        </a>
                        .
                    </p>
                </div>
                <div className="rounded-r rounded-bl-lg bottom-0 left-0 h-100 lg:hidden w-full flex mt-10 flex-col justify-center">
                    <div className="flex">
                        <div className="bg-white rounded-br-lg w-300 pl-5 pr-5 pb-7 pt-5">
                            <img
                                src="/images/TenderTracer_logo.png"
                                alt="Tendertracer Logo"
                            />
                        </div>
                        <div className="w-full bg-[length:1100px_550px] bg-[url('/public/images/onboarding_bg.webp')]" />
                    </div>
                </div>
            </div>
        </div>
    );
};
