import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Spinner } from "../../../components/Spinner";
import clsx from "clsx";
import ReactCodeInput from "react-code-input";
import { API_DEFAULT_HEADERS, API_DOMAIN } from "../../../APIconfig";
import ChangePhone from "./changePhone";

const PhoneVerification = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [isPinCodeValid, setIsPinCodeValid] = useState<boolean>(true);
    const [countdown, setCountdown] = useState<number>(30);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        document.title = "Verificatie | TenderTracer";
    }, []);

    const resendVerificationCode = async () => {
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });

        Auth.currentSession().then((res) => {
            let accessToken = res.getIdToken();
            let jwt = accessToken.getJwtToken();

            var raw = JSON.stringify({
                id: attributes.sub,
            });

            var requestOptions: any = {
                method: "POST",
                headers: new Headers(API_DEFAULT_HEADERS(jwt)),
                body: raw,
                redirect: "follow",
            };

            fetch(`${API_DOMAIN}/sms/send`, requestOptions)
                .then(async (response) => {
                    setCountdown(30);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    };

    async function signOut() {
        try {
            await Auth.signOut();
            window.location.href = "/";
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        let timer: any;

        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }

        if (countdown === 0) {
            clearInterval(timer);
        }

        return () => clearInterval(timer);
    }, [countdown]);

    async function confirmCode(code: string) {
        const { attributes } = await Auth.currentAuthenticatedUser({
            bypassCache: true,
        });
        if (code.length === 6) {
            Auth.currentSession().then((res) => {
                let accessToken = res.getIdToken();
                let jwt = accessToken.getJwtToken();

                var raw = JSON.stringify({
                    id: attributes.sub,
                    smscode: code,
                });

                var requestOptions: any = {
                    method: "POST",
                    headers: new Headers(API_DEFAULT_HEADERS(jwt)),
                    body: raw,
                    redirect: "follow",
                };

                fetch(`${API_DOMAIN}/sms/confirm`, requestOptions)
                    .then(async (response) => {
                        if (response.status === 200) {
                            window.location.href = "/";
                        } else {
                            setIsPinCodeValid(false);
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        setIsPinCodeValid(false);
                        console.log(error);
                        setLoading(false);
                    });
            });
        }
    }

    return page === 1 ? (
        <section className="h-full w-full overflow-y-auto flex justify-center pt-[10%] relative bg-[#284F82] bg-center bg-cover bg-[url('/public/images/onboarding_bg.webp')]">
            <div className="ml-5 mr-5 mt-5 w-[500px]">
                <div className="bg-white rounded-lg mb-2 p-2 border shadow">
                    <div className="w-full bg-white rounded-lg py-2">
                        <img
                            className="w-[150px]"
                            src="/images/TenderTracer_logo.png"
                        />
                    </div>
                    <p className="font-bold pl-3 text-lg">
                        Telefoonnummer verifiëren
                    </p>
                    <p className="pl-3 text-sm">
                        We hebben een verificatiecode per SMS naar je
                        telefoonnummer gestuurd. Voer de code hieronder in om
                        verder te gaan.
                    </p>
                    <div className="w-full p-5 h-full rounded-lg bg-white lg:px-3 px-3 flex flex-col ">
                        <div className="flex relative flex-col items-center justify-center">
                            {loading && (
                                <div className="absolute w-[500px] rounded-lg bg-opacity-80 h-full bg-white items-center justify-center flex">
                                    <Spinner />
                                </div>
                            )}
                            {!isPinCodeValid && (
                                <p className="text-primary-pink">
                                    Code is onjuist. Probeer het nogmaals.
                                </p>
                            )}
                            <ReactCodeInput
                                type="text"
                                fields={6}
                                isValid={isPinCodeValid}
                                onChange={confirmCode}
                                inputStyle={{
                                    width: "3em",
                                    height: "4em",
                                    margin: "0.5em",
                                    fontSize: "1em",
                                    borderRadius: "5px",
                                    border: "2px solid #284F82",
                                    textAlign: "center",
                                }}
                                name={"PhoneVerification"}
                                inputMode={"tel"}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-1 rounded-lg bg-white p-3 border text-center shadow">
                    <p
                        onClick={
                            countdown === 0 ? resendVerificationCode : () => ""
                        }
                        className={clsx(
                            countdown !== 0
                                ? "text-sm opacity-50 cursor-not-allowed"
                                : "text-sm text-primary-green cursor-pointer hover:underline"
                        )}
                    >
                        {`Verificatiecode opnieuw versturen ${
                            countdown !== 0 ? `(${countdown})` : ""
                        }`}
                    </p>
                </div>
                <div className="mt-1 rounded-lg bg-white p-3 text-center border shadow">
                    <p
                        onClick={() => setPage(2)}
                        className="text-sm text-primary-green cursor-pointer hover:underline"
                    >
                        Telefoonnummer wijzigen
                    </p>
                </div>
                <div className="mt-1 rounded-lg bg-white p-3 text-center border shadow">
                    <p
                        onClick={signOut}
                        className="text-sm text-primary-pink cursor-pointer hover:underline"
                    >
                        Uitloggen
                    </p>
                </div>
            </div>
        </section>
    ) : (
        <ChangePhone setPage={setPage} />
    );
};

export default PhoneVerification;
